<template>
  <v-form @submit.prevent>
    <v-card>
      <v-card-title>
        {{ formTitle }}
      </v-card-title>
      <v-card-text>
        <v-row dense>
          <v-col cols="12" sm="6">
            <v-text-field
              v-model="item.name"
              :error-messages="errors.name"
              :label="formMixin_getRequiredFieldLabel($t('general.title'))"
              @input="formMixin_clearErrors('name')"
            />
          </v-col>

          <v-col cols="12" sm="6">
            <v-text-field
              v-model="item.price"
              :error-messages="errors.price"
              :label="$t('general.price')"
              type="number"
              @input="formMixin_clearErrors('price')"
            />
          </v-col>

          <v-col cols="12" sm="6">
            <ContactAutocomplete
              v-model="item.client_id"
              :error-messages="errors.client_id"
              @input="formMixin_clearErrors('client_id')"
            />
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions>
        <v-spacer />
        <v-btn text @click.native="$emit('cancel', item)">
          {{ $t('general.controls.cancel') }}
        </v-btn>
        <v-btn
          color="primary"
          text
          :disabled="loading"
          :loading="loading"
          @click="$emit('save', item)"
        >
          {{ $t('general.controls.save') }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-form>
</template>

<script>
import formMixin from '@/mixins/form-mixin';
import clone from 'just-clone';
import ContactAutocomplete from '@/components/autocompletes/ContactAutocomplete';

export default {
  name: 'ProductForm',

  components: { ContactAutocomplete },

  mixins: [formMixin],

  props: {
    product: {
      type: Object,
      required: true,
    },
    errors: {
      type: Object,
      default: () => ({}),
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      item: {},
    };
  },

  computed: {
    formTitle() {
      return this.$t(`general.modal_titles.${this.product.id ? 'edit_product' : 'new_product'}`);
    },
  },

  created() {
    this.item = clone(this.product);
  },
};
</script>

<style scoped></style>
