<template>
  <v-dialog
    :value="true"
    :fullscreen="$vuetify.breakpoint.xsOnly"
    transition="slide-y-reverse-transition"
    max-width="800"
    scrollable
    persistent
  >
    <ProductForm
      :product="newProduct"
      :errors="productValidationErrors"
      :loading="$store.getters.loading['post:api/products']"
      @clear:errors="CLEAR_PRODUCT_VALIDATION_ERRORS"
      @cancel="goBack"
      @save="onSave"
    />
  </v-dialog>
</template>

<script>
import ProductForm from '@/components/forms/ProductForm';
import { mapActions, mapMutations, mapState } from 'vuex';

export default {
  name: 'CreateProduct',

  components: { ProductForm },

  computed: {
    ...mapState('products', ['newProduct', 'productValidationErrors']),
  },

  created() {
    this.SET_PRODUCT_VALIDATION_ERRORS({});
  },

  methods: {
    ...mapActions('products', ['storeProduct']),
    ...mapMutations('products', [
      'SET_PRODUCT_VALIDATION_ERRORS',
      'CLEAR_PRODUCT_VALIDATION_ERRORS',
    ]),

    onSave(product) {
      this.storeProduct(product).then(() => {
        this.goBack();
      });
    },

    goBack() {
      this.$router.push(this.$route.meta.getBackRoute());
    },
  },
};
</script>

<style scoped></style>
